( function() {
	let init = () => {
		if ( 'interactive' !== document.readyState && 'complete' !== document.readyState ) {
			return;
		}

		// noop
		init = () => {};

		// Weeee modern browser
		// Check if video's get inserted
		if ( 'MutationObserver' in window ) {
			const mutationObserver = new MutationObserver( ( ( mutations ) => {
				// make sure there are mutations
				if ( 1 > mutations.length ) {
					return;
				}

				// Loop through all mutations
				for ( let i = 0; i < mutations.length; i++ ) {
					// Make sure there are added nodes
					if ( 1 > mutations[i].addedNodes.length ) {
						continue;
					}

					// Loop Through all added nodes
					for ( let j = 0; j < mutations[i].addedNodes.length; j++ ) {
						// If no html element, just carry on
						if ( !( mutations[i].addedNodes[j] instanceof HTMLElement ) ) {
							continue;
						}

						// if autoplay in view elements inside of one of the nodes, handle them and break the loop
						if ( 0 < mutations[i].addedNodes[j].querySelectorAll( 'video[data-autoplay-in-view]:not([handled])' ).length ) {
							document.dispatchEvent( new CustomEvent( 'autoplay-in-view:do-init', {
								bubbles: true,
								cancelable: true,
							} ) );

							return;
						}
					}
				}

			} ) );

			// Starts listening for changes in the root HTML element of the page.
			mutationObserver.observe( document.documentElement, {
				attributes: false,
				characterData: false,
				childList: true,
				subtree: true,
			} );
		}
	};

	document.addEventListener( 'readystatechange', () => {
		init();
	}, false );

	init();
}() );
