import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

const leftpad = ( int ) => {
	if ( 10 > int ) {
		return `0${int}`;
	}

	return `${int}`;
};

defineCustomElement( 'mr-countdown', {
	attributes: [
		'datetime',
	],
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		init() {
			this.elements = {};
			this.target = new Date( this.datetime );
		}

		render() {
			const fragment = document.createDocumentFragment();

			const generatePart = ( key ) => {
				const div = document.createElement( 'div' );
				div.className = 'countdown__part';

				const countdown = document.createElement( 'span' );
				countdown.className = 'countdown__countdown';

				const label = document.createElement( 'span' );
				label.className = 'countdown__label';

				label.textContent = key;

				div.appendChild( countdown );
				div.appendChild( label );

				fragment.appendChild( div );

				return countdown;
			};

			[
				'days',
				'hours',
				'minutes',
				'seconds',
			].forEach( ( key ) => {
				this.elements[key] = generatePart( key );
			} );

			this.update();

			this.el.appendChild( fragment );
		}

		bind() {
			this.countdownInterval = setInterval( () => {
				this.update();
			}, 500 );
		}

		update() {
			window.requestAnimationFrame( () => {
				const diff = this.countdown();

				Object.keys( diff ).forEach( ( key ) => {
					if ( this.elements[key] ) {
						this.elements[key].textContent = leftpad( diff[key] );
					}
				} );
			} );
		}

		countdown() {
			const now = new Date();
			const diff = this.target.getTime() - now.getTime();

			const days = Math.floor( diff / 86400000 );
			const hours = Math.floor( ( diff / 3600000 ) % 24 );
			const minutes = Math.floor( ( diff / 60000 ) % 60 );
			const seconds = Math.floor( ( diff / 1000 ) % 60 );

			return {
				days,
				hours,
				minutes,
				seconds,
			};
		}
	},
} );
