import { MrModalDialog } from '@mrhenry/wp--mr-interactive';
import { doOncePerHour } from '../helpers/do-once-per';

class MrPopup extends MrModalDialog {

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this.hasRendered = false;
	}

	connectedCallback() {
		super.connectedCallback();

		this.getContent();
	}

	async getContent() {
		try {
			let preview = false;
			if ( this.dataset.preview && '1' === this.dataset.preview ) {
				preview = true;
			}

			const resp = await fetch( `/wp-json/popup/general.json?preview=${preview}` );
			const popupResponse = await resp.json();
			if ( !popupResponse || !popupResponse.html ) {
				return;
			}

			if ( !this.hasRendered ) {
				// add content
				this.innerHTML = popupResponse.html + this.innerHTML;
				this.hasRendered = true;
			}

			// open popup when preview is active
			if ( popupResponse.preview ) {
				this.updateState( 'open' );

				return;
			}

			// Only open per hourly visit
			// TODO: Don't use this but doOncePerVisit instead in the new version, this is to spam the user.
			doOncePerHour( popupResponse.id, () => {
				this.updateState( 'open' );
			} );
		} catch ( err ) {
			console.warn( err ); // do nothing

			return;
		}
	}

	willOpen() {
		super.willOpen();

		document.body.classList.add( 'is-showing-popup' );

		return Promise.resolve();
	}

	willClose() {
		super.willClose();

		document.body.classList.remove( 'is-showing-popup' );

		return Promise.resolve();
	}
}

customElements.define( 'mr-popup', MrPopup );
