import { BaseController } from '../controllers/base';

import { parseHTML,
	renderNodes,
	cleanNodes } from '../util/html';

interface HistoryState {
	href: string
	title: string|null
	root: boolean
	by: string
}

class OverlayController extends BaseController<HTMLElement> {
	_isShown = false;

	_upState: HistoryState | null = null;

	stripFromResponse: Array<string> = [
		'link[rel="up"]',
	];

	originalClasses: Array<string> = [];

	/**
		 * `isShown` is a boolean that tracks
		 * if the overlay is currently open or not
		 * */
	get isShown(): boolean {
		return !!this._isShown;
	}

	set isShown( to:boolean ) {
		this._isShown = !!to;
		this.el.classList.toggle( 'is-hidden', !this._isShown );
		this.el.classList.toggle( 'is-shown', this._isShown );
		document.body.classList.toggle( 'is-showing-overlay', this._isShown );
	}

	/**
		 * Original state is the History API state for the parent page
		 * (the page below the overlay)
		 * (not neccesarily the first page that was loaded)
		 * */
	get upState(): HistoryState | null {
		if ( !this._upState ) {
			return null;
		}

		return Object.assign( {}, this._upState );
	}

	set upState( to: HistoryState | null ) {
		if ( null === to ) {
			this._upState = null;

			return;
		}

		this._upState = Object.assign( {}, to );
	}

	/**
		 * This method gets run when a `<mr-overlay>`
		 * appears in the DOM, either after DOM ready
		 * or when HTML gets attached later on in the browsing session
		 */
	override render(): void {
		requestAnimationFrame( () => {
			this.setupViewReplacement();
			this.setupEventListeners();
		} );
	}

	setupViewReplacement(): void {
		this.stripFromResponse.push( this.el.tagName );

		// Store the original classes so we can always revert back to the default state
		// while rendering in different aspects
		this.originalClasses = Array.from( this.el.classList );

		// Add <link rel="up" href="/"> inside an overlay to fetch a background view
		const upLink = this.el.querySelector( 'link[rel="up"]' );

		if ( upLink ) {
			const href = upLink.getAttribute( 'href' );
			if ( !href ) {
				return;
			}

			fetch( href, {
				credentials: 'include',
			} ).then( ( res ) => {
				return res.text();
			} ).then( ( html ) => {
				const parsedHTML = parseHTML( html );
				const title = parsedHTML.title;
				const content = parsedHTML.content;

				if ( !content ) {
					return;
				}

				if ( content.getElementsByTagName( this.el.tagName ).item( 0 ) ) {
					const original = content.getElementsByTagName( this.el.tagName ).item( 0 );
					if ( original ) {
						this.originalClasses = Array.from( original.classList );
					}
				}

				const fragment = document.createDocumentFragment();

				// Clean certain selectors from the up state to avoid infinite loops
				const clean = cleanNodes( content, this.stripFromResponse );

				renderNodes( clean, fragment );

				if ( this.el.parentNode ) {
					this.el.parentNode.insertBefore( fragment, this.el );
				}

				// The upState is not the overlay view but the background view
				this.upState = {
					href: href,
					title: title,
					root: true,
					by: this.el.tagName,
				};

				// We need to replace the current state to handle `popstate`
				const state = {
					href: window.location.href,
					title: document.title,
					root: false,
					by: this.el.tagName,
				};


				history.replaceState( state, state.title, state.href );

				// Set isShown so that the closing handler works correctly
				this.isShown = true;
			} );
		} else {
			// Currently not inside an overlay view, but an overlay might open
			// (because an empty <mr-overlay> is present)
			// so we store the current state to support `popstate` events
			this.upState = {
				href: window.location.href,
				title: document.title,
				root: true,
				by: this.el.tagName,
			};


			history.replaceState( this.upState, this.upState.title || '', this.upState.href );
		}
	}

	setupEventListeners(): void {
		const hideHandler = ( e: Event ) => {
			if ( this.isShown ) {
				e.preventDefault();

				this.hide();

				const upState = this.upState;
				if ( !upState ) {
					return;
				}


				history.pushState( upState, upState.title || '', upState.href );
				document.title = upState.title || '';
			}
		};

		this.on( 'click', ( e ) => {
			if ( e.target === this.el ) {
				hideHandler( e );
			}
		}, this.el );

		this.on( 'click .js-overlay-show', ( e, target ) => {
			if ( target && ( target instanceof HTMLAnchorElement ) && target.href ) {
				e.preventDefault();
				this.show( target.href );
			}
		}, document.body );

		this.on( 'click .js-overlay-hide', ( e ) => {
			hideHandler( e );
		}, document.body );

		this.on<PopStateEvent>( 'popstate', ( e: PopStateEvent | Event ) => {
			if ( !( e instanceof PopStateEvent ) ) {
				return;
			}

			// Only handle states that were set by `mr-overlay`
			// to avoid messing with other elements that use the History API
			if ( e.state && e.state.by === this.el.tagName && e.state.href ) {
				const {
					href, title,
				} = e.state;

				if ( !this.upState ) {
					return;
				}

				const {
					href: upHref, title: upTitle,
				} = this.upState;

				const hasRequestedUpState = href === upHref && title === upTitle;

				if ( e.state.root && hasRequestedUpState ) {
					// Trigger hide() if the popstate requests the root view
					this.hide();
					document.title = this.upState.title || '';
				} else {
					// Show the overlay() if we closed the overlay before
					this.show( e.state.href, false );
				}
			}
		}, window );
	}

	show( href: string, pushState = true ): Promise<void> {
		return fetch( href, {
			credentials: 'include',
		} ).then( ( res ) => {
			return res.text();
		} ).then( ( html ) => {
			const {
				title, content, meta,
			} = parseHTML( html, this.el.tagName );

			meta.forEach( ( tag ) => {
				let selector = 'meta';

				if ( tag.property ) {
					selector = `${selector}[property="${tag.property}"]`;
				}

				if ( tag.name ) {
					selector = `${selector}[name="${tag.name}"]`;
				}

				const match = document.head.querySelector( selector ) as HTMLMetaElement | null;

				if ( match ) {
					match.setAttribute( 'content', tag.content || '' );

					return;
				}

				const append = document.createElement( 'meta' );
				append.setAttribute( 'property', tag.property || '' );
				append.content = tag.content || '';
				append.name = tag.name || '';
				document.head.appendChild( append );
			} );

			if ( content ) {
				const newClasses = Array.from( content.classList );
				this.el.className = '';
				// This crashes in IE11
				// this.el.classList.add(...newClasses);
				newClasses.forEach( ( c ) => {
					return this.el.classList.add( c );
				} );

				this.isShown = true;

				// Clean certain selectors from the up state to avoid infinite loops
				const clean = cleanNodes( content, this.stripFromResponse );

				renderNodes( clean, this.el );

				requestAnimationFrame( () => {
					this.el.scrollTop = 0;
				} );

				document.title = title || '';

				if ( pushState ) {
					const state = {
						href: href,
						title: title,
						by: this.el.tagName,
					};

					history.pushState( state, title || '', href );
				}
			}
		} );
	}

	hide(): void {
		this.isShown = false;

		while ( this.el.firstChild ) {
			this.el.removeChild( this.el.firstChild );
		}

		if ( this.originalClasses && Array.isArray( this.originalClasses ) ) {
			this.el.className = '';

			// This crashes in IE11
			// this.el.classList.add(...this.originalClasses);
			this.originalClasses.forEach( ( c ) => {
				return this.el.classList.add( c );
			} );
		}
	}
}

const overlay = {
	attributes: [],
	controller: OverlayController,
};

export default overlay;
