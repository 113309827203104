class MrWorkWithUsFeed extends HTMLElement {
	connectedCallback() {
		if ( !( 'IntersectionObserver' in window ) ) {
			return;
		}

		// Wait for first (or next) frame render
		requestAnimationFrame( () => {
			this._observer = new IntersectionObserver( ( entries ) => {
				for ( let i = 0; i < entries.length; i += 1 ) {
					const entry = entries[i];

					if ( !entry || !entry.target ) {
						continue;
					}

					if ( entry.target === this && entry.isIntersecting ) {
						if ( this._didSetInnerHTML ) {
							return;
						}

						// Any errors that happen below are fatal and cannot be retried.
						// No point to set this to true after actually creating a map.
						this._didSetInnerHTML = true;

						if ( this._observer ) {
							this._observer.disconnect();
							this._observer = null;
						}

						this.setInnerHTML();
					}
				}
			} );

			this._observer.observe( this );
		} );
	}

	disconnectedCallback() {
		if ( this._observer ) {
			this._observer.disconnect();
			this._observer = null;
		}

		this.innerHTML = '';
		this.removeAttribute( 'is-loaded' );

		this._didSetInnerHTML = false;
	}

	async setInnerHTML() {
		try {
			this.innerHTML = '';

			const resp = await fetch( 'https://workwithus.io/ubn/jobs.json' );
			if ( !resp.ok ) {
				console.log( resp );

				return;
			}

			const data = await resp.json();
			console.log( data );
			// data.meta contains pagination info
			// We currently do not know how to use this on the endpoint

			if ( !data || !data.data ) {
				return;
			}

			data.data.forEach( ( item ) => {
				if ( item.link ) {
					this.innerHTML += `
						<article class="work-with-us__item">
							<a
								class="work-with-us__item__link"
								href="${item.link}"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span class="work-with-us__item__location">
									${item.location}
								</span>

								<span class="work-with-us__item__company">
									${item.company}
								</span>

								<span class="work-with-us__item__title">
									${item.title}
								</span>
							</a>
						</article>
					`;
				}

			} );


			requestAnimationFrame( () => {
				this.setAttribute( 'is-loaded', 'is-loaded' );
			} );
		} catch ( err ) {
			console.warn( err );
		}
	}
}

customElements.define( 'mr-work-with-us-feed', MrWorkWithUsFeed );
