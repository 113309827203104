import { defineCustomElement, BaseController, attachMediaAttributes } from '@mrhenry/wp--custom-elements-helpers';
import dynamicProperties from '@mrhenry/wp--dynamic-properties';

class FixToViewportController extends BaseController {
	init() {
		this.dimensions = dynamicProperties( {
			windowHeight: () => {
				return window.innerHeight;
			},
			top: () => {
				return this.el.getBoundingClientRect().top;
			},
		} );
	}

	render() {
		if ( this.matchesMedia ) {
			Object.assign( this.el.style, {
				display: 'block',
				height: `${Math.ceil( this.dimensions.windowHeight - this.dimensions.top )}px`,
				'overflow-y': 'scroll',
				'-webkit-overflow-scrolling': 'touch',
			} );
		} else {
			Object.assign( this.el.style, {
				display: 'block',
				height: 'auto',
				'overflow-y': 'auto',
			} );
		}
		this.emit( 'fix-to-viewport:resize', document.body );
	}

	bind() {
		let resizeTimeout;

		this.on( 'resize', () => {
			if ( resizeTimeout ) {
				clearTimeout( resizeTimeout );
			}

			resizeTimeout = setTimeout( () => {
				this.render();
			}, 128 );
		}, window );
	}
}

attachMediaAttributes( FixToViewportController );

defineCustomElement( 'mr-fix-to-viewport', {
	attributes: [],
	controller: FixToViewportController,
} );
