import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-mapbox', {
	attributes: [
		'selector',
		{
			attribute: 'center-lng',
			type: 'number',
		},
		{
			attribute: 'center-lat',
			type: 'number',
		},
	],
	controller: class extends BaseController {
		resolve() {
			const mapboxVersion = 'v1.1.0'; // previously v0.53.1

			const loadJS = function() {
				return new Promise( ( resolve, reject ) => {
					const script = document.createElement( 'script' );
					script.src = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.js`;
					script.onload = () => {
						return resolve();
					};
					script.onerror = () => {
						return reject( new Error( 'mapbox gl js failed to load' ) );
					};
					script.async = true;

					const first = document.head.getElementsByTagName( 'script' )[0];
					first.parentNode.insertBefore( script, first );
				} );
			};

			const loadCSS = function() {
				return new Promise( ( resolve, reject ) => {
					const link = document.createElement( 'link' );
					link.rel = 'stylesheet';
					link.href = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.css`;
					link.onload = () => {
						return resolve();
					};
					link.onerror = () => {
						return reject( new Error( 'mapbox gl css failed to load' ) );
					};

					const first = document.head.getElementsByTagName( 'link' )[0];
					first.parentNode.insertBefore( link, first );
				} );
			};

			return Promise.all( [
				super.resolve(),
				loadJS(),
				loadCSS(),
			] );
		}

		init() {
			// XXX_SECRET
			window.mapboxgl.accessToken = 'pk.eyJ1IjoibXJoZW5yeSIsImEiOiJjamhqMGVka3UwaWt1MzBrZHFoNmJxanMxIn0.WFtYFcw3d7lbYCsXaQ_X1A';

			this.elements = {};
			this.elements.container = this.el.getElementsByClassName( 'js-mapbox-map' )[0];
			this.elements.hotspots = Array.from( document.body.querySelectorAll( this.selector ) );

			this.geojson = this.buildGeoJSON();

			return this.initMap().then( () => {
				this.drawPoints();
			} );
		}

		buildGeoJSON() {
			const geojson = {};
			geojson.type = 'FeatureCollection';

			geojson.features = Array.from( this.elements.hotspots, ( el ) => {
				const color = el.getAttribute( 'data-hotspot-color' );
				const label = el.getAttribute( 'data-hotspot-label' );
				const lng = parseFloat( el.getAttribute( 'data-hotspot-longitude' ).replace( ',', '.' ) );
				const lat = parseFloat( el.getAttribute( 'data-hotspot-latitude' ).replace( ',', '.' ) );

				return {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [
							lng,
							lat,
						],
					},
					properties: {
						popup: el.innerHTML,
						color: color,
						label: label,
					},
				};
			} );

			return geojson;
		}

		bind() {
			this.on( 'fix-to-viewport:resize', () => {
				this.map.resize();
			}, document.body );

			const popup = new window.mapboxgl.Popup( {
				closeButton: false,
				closeOnClick: false,
				offset: 18,
			} );

			const hidePopup = () => {
				if ( popup ) {
					popup.remove();
				}
			};

			const showPopup = ( feature ) => {
				if ( popup ) {
					popup.remove();
				}

				const wrapped = `<div class="mapbox__popup">${feature.properties.popup}</div>`;

				popup
					.setLngLat( feature.geometry.coordinates )
					.setHTML( wrapped )
					.addTo( this.map )
					.setMaxWidth( '330px' ); // larger than the actual value which is 314px
			};

			this.elements.hotspots.forEach( ( hotspot ) => {
				this.on( 'click', ( e ) => {
					const longitude = parseFloat( e.currentTarget.getAttribute( 'data-hotspot-longitude' ).replace( ',', '.' ) );
					const latitude = parseFloat( e.currentTarget.getAttribute( 'data-hotspot-latitude' ).replace( ',', '.' ) );

					const feature = this.geojson.features.find( ( f ) => {
						const [
							lng,
							lat,
						] = f.geometry.coordinates;

						return ( lng === longitude && lat === latitude );
					} );

					hidePopup();

					this.flyTo( longitude, latitude ).then( () => {
						showPopup( feature );
					} );
				}, hotspot );
			} );

			this.map.on( 'mouseenter', 'points', () => {
				const canvas = this.map.getCanvas();
				Object.assign( canvas.style, {
					cursor: 'pointer',
				} );
			} );

			this.map.on( 'click', 'points', ( e ) => {
				const canvas = this.map.getCanvas();
				Object.assign( canvas.style, {
					cursor: 'pointer',
				} );

				showPopup( e.features[0] );
			} );

			this.map.on( 'mouseleave', 'points', () => {
				const canvas = this.map.getCanvas();
				Object.assign( canvas.style, {
					cursor: '',
				} );
			} );
		}

		initMap() {
			return new Promise( ( resolve ) => {
				this.map = new window.mapboxgl.Map( {
					container: this.elements.container,
					style: 'mapbox://styles/mapbox/light-v9',
					center: [
						this.centerLng,
						this.centerLat,
					],
					zoom: 14,
				} );

				this.map.on( 'load', () => {
					return resolve();
				} );
			} );
		}

		drawPoints() {
			this.map.addLayer( {
				id: 'points',
				type: 'circle',
				source: {
					type: 'geojson',
					data: this.geojson,
				},
				paint: {
					'circle-color': {
						type: 'identity',
						property: 'color',
					},
					'circle-radius': 14,
				},
			} );

			this.map.addLayer( {
				id: 'labels',
				source: {
					type: 'geojson',
					data: this.geojson,
				},
				interactive: false,
				type: 'symbol',
				layout: {
					'text-field': '{label}',
					'text-size': 11,
				},
				paint: {
					'text-color': '#fff',
				},
			} );

			this.map.addLayer( {
				id: 'offficalHQ',
				source: {
					type: 'geojson',
					data: {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [
								4.4021,
								51.2335,
							],
						},
					},
				},
				type: 'symbol',
				layout: {
					'text-field': 'UBN',
					'text-size': 14,
				},
				paint: {
					'text-color': '#00b3ff',
				},
			} );
		}

		flyTo( lng, lat ) {
			if ( this.isFlying ) {
				return this.isFlying;
			}

			this.isFlying = new Promise( ( resolve ) => {
				this.map.flyTo( {
					center: [
						lng,
						lat,
					],
					zoom: 16,
					bearing: 0,
					speed: 1,
				} );

				const listener = () => {
					this.isFlying = undefined;
					this.map.off( 'moveend', listener );
					resolve();
				};

				this.map.on( 'moveend', listener );
			} );

			return this.isFlying;
		}
	},
} );
