import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-navigation', {
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		init() {
			this.elements = {};
			this.elements.toggle = document.body.getElementsByClassName( 'js-navigation-toggle' )[0];
		}

		bind() {
			this.on( 'click', ( e ) => {
				e.stopPropagation();
				e.preventDefault();
				this.el.classList.toggle( 'is-shown' );
			}, this.elements.toggle, false );
		}
	},
} );
