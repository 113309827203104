import '@mrhenry/wp--bugsnag-config';

import '@mrhenry/wp--autoplay-in-view';
import { MrPlayPauseToggle } from '@mrhenry/wp--mr-interactive';

import './modules/autoplay-in-view-do-init';
import './modules/blur-circle';
import './modules/countdown';
import './modules/fix-to-viewport';
import './modules/glitch';
import './modules/mapbox';
import './modules/mr-input-sink';
import './modules/mr-popup';
import './modules/navigation';
import './modules/overlay';
import './modules/picture-frame';
import './modules/program';
import './modules/slideshow';
import './modules/stack';
import './modules/tooltip';
import './modules/video';
import './modules/work-with-us-feed';

customElements.define( 'mr-play-pause-toggle', MrPlayPauseToggle );
