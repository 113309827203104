import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-stack', {
	attributes: [
		{
			attribute: 'stacked',
			type: 'bool',
		},
	],
	controller: class extends BaseController {
		set stacked( to ) {
			if ( to ) {
				this.renderStackedGrid();
			} else {
				this.renderNormalGrid();
			}
		}

		init() {
			this.elements = {};
			this.elements.items = Array.from( this.el.children );
			this.elements.columns = [];

			this.className = 'stack';

			const classList = Array.from( this.el.classList ).filter( ( c ) => {
				return 'is-resolved' !== c;
			} );

			if ( 1 === classList.length ) {
				this.className = classList[0];
			}
		}

		render() {
			this.clearGrid();

			if ( this.stacked ) {
				this.renderStackedGrid();
			} else {
				this.renderNormalGrid();
			}
		}

		bind() {
			this.on( 'resize', () => {
				if ( this.stacked ) {
					this.renderStackedGrid();
				} else {
					this.renderNormalGrid();
				}
			}, window );
		}

		clearGrid() {
			while ( this.el.hasChildNodes() ) {
				this.el.removeChild( this.el.firstChild );
			}
		}

		renderNormalGrid() {
			this.el.classList.remove( 'is-stacked' );

			this.clearGrid();

			const fragment = document.createDocumentFragment();

			this.elements.items.forEach( ( node ) => {
				fragment.appendChild( node );
			} );

			this.el.appendChild( fragment );

			this.currentColumnCount = undefined;
		}

		renderStackedGrid() {
			const sizer = document.createElement( 'div' );
			sizer.className = `${this.className}__item ${this.className}__item--sizer`;
			sizer.style.visibility = 'hidden';
			this.el.appendChild( sizer );

			const columnCount = Math.round( this.el.offsetWidth / sizer.offsetWidth );

			this.el.removeChild( sizer );

			if ( columnCount === this.currentColumnCount ) {
				return;
			}

			this.el.classList.add( 'is-stacked' );

			// Clear grid
			this.clearGrid();

			this.elements.columns = [];
			const fragment = document.createDocumentFragment();

			while ( this.elements.columns.length < columnCount ) {
				const clone = document.createElement( 'div' );
				clone.className = `${this.className}__column`;
				fragment.appendChild( clone );
				this.elements.columns.push( clone );
			}

			this.elements.items.forEach( ( node, index ) => {
				const column = this.elements.columns[index % this.elements.columns.length];
				if ( column ) {
					column.appendChild( node );
				}
			} );

			this.el.appendChild( fragment );

			this.currentColumnCount = columnCount;
		}
	},
} );
