import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-slideshow', {
	attributes: [
		{
			attribute: 'loop',
			type: 'bool',
		},
		{
			attribute: 'auto',
			type: 'int',
		},
		{
			attribute: 'current',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		set current( to ) {
			let parsed = parseInt( to, 10 );

			if ( parsed === this.current ) {
				return;
			}

			const max = this.elements.items.length;

			// If we're at the last slide and navigated 'Next'
			if ( parsed >= max ) {
				// Back to first slide if carousel has loop set to true
				if ( this.loop ) {
					parsed = 0;
				} else {
					parsed = max - 1;
				}
			}

			// If we're at the first slide and navigated 'Previous'
			if ( 0 > parsed ) {
				// Jump to last slide if carousel has loop set to true
				if ( this.loop ) {
					parsed = max - 1;
				} else {
					parsed = 0;
				}
			}

			this.el.setAttribute( 'current', parsed );

			this.render();
		}

		set auto( to ) {
			const parsed = parseInt( to, 10 );

			if ( parsed === this.auto ) {
				return;
			}

			if ( 0 >= parsed ) {
				this.el.removeAttribute( 'auto' );
			} else {
				this.el.setAttribute( 'auto', parsed );
			}

			this.start();
		}

		start() {
			this.stop();

			if ( this.auto && 0 < this.auto ) {
				this.looper = setInterval( () => {
					this.next();
				}, this.auto );
			}
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			this.current += 1;
		}

		previous() {
			this.current -= 1;
		}

		resolve() {
			if ( 1 === this.el.children.length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			this.elements = {};
			this.elements.items = Array.from( this.el.children );

			if ( !this.current ) {
				this.current = 0;
			}

			this.start();
		}

		render() {
			this.elements.items.forEach( ( item, i ) => {
				if ( item.classList.contains( 'is-active' ) ) {
					item.classList.remove( 'is-active' );
				}

				if ( i === this.current ) {
					item.classList.add( 'is-active' );
				}
			} );
		}

		destroy() {
			this.stop();
			super.destroy();
		}
	},
} );
