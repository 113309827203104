import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-blur-circle', {
	attributes: [
		{
			attribute: 'inverted',
			type: 'bool',
		},
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.image = this.el.getElementsByTagName( 'img' )[0];
		}

		render() {
			if ( this.inverted ) {
				return this.renderInverted();
			}

			const src = this.elements.image.currentSrc || this.elements.image.src;
			const srcset = this.elements.image.srcset;

			const face = document.createElement( 'div' );
			face.className = 'lineup__poster-image--face';
			face.style.backgroundImage = `url(${src})`;

			const blur = this.elements.image.cloneNode( false );
			blur.className = 'lineup__poster-image--blur';
			blur.src = `${src}&blur=160`;
			blur.srcset = srcset.replace( /\?/gi, '?blur=160&' );

			const fragment = document.createDocumentFragment();
			fragment.appendChild( blur );
			fragment.appendChild( face );

			while ( this.el.hasChildNodes() ) {
				this.el.removeChild( this.el.firstChild );
			}

			this.el.appendChild( fragment );

			return this;
		}

		renderInverted() {
			const src = this.elements.image.currentSrc || this.elements.image.src;
			const srcset = this.elements.image.srcset;

			// Face is actually blurred
			const face = document.createElement( 'div' );
			face.className = 'lineup__poster-image--face';
			face.style.backgroundImage = `url('${src}&blur=160')`;

			// Blur is actually not blurred
			// Boom. Mind blown.
			const blur = this.elements.image.cloneNode( false );
			blur.className = 'lineup__poster-image--blur';
			blur.src = src;
			blur.srcset = srcset;

			const fragment = document.createDocumentFragment();
			fragment.appendChild( blur );
			fragment.appendChild( face );

			while ( this.el.hasChildNodes() ) {
				this.el.removeChild( this.el.firstChild );
			}

			this.el.appendChild( fragment );

			return this;
		}
	},
} );
