import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-picture-frame', {
	attributes: [
		'data-aspect-ratio',
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.image = this.el.getElementsByClassName( 'picture-frame__image' )[0];
		}

		render() {
			const url = `${this.elements.image.src}&blur=160`;
			this.el.style.backgroundImage = `url(${url})`;
		}
	},
} );
