import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-glitch', {
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		init() {
			const shouldShow = 0.4 > Math.random();
			const glitches = Array.from( this.el.querySelectorAll( '[glitch-src]' ) );

			const clear = () => {
				glitches.forEach( ( g ) => {
					return this.el.removeChild( g );
				} );
			};

			if ( !shouldShow ) {
				clear();

				return Promise.resolve( true );
			}

			return new Promise( ( resolve ) => {
				const glitch = glitches[Math.floor( Math.random() * glitches.length )];

				glitch.onload = () => {
					this.el.style.backgroundImage = `url(${glitch.src})`;
					resolve();
				};

				glitch.src = glitch.getAttribute( 'glitch-src' );

				clear();
			} );
		}

		bind() {
			setTimeout( () => {
				window.requestAnimationFrame( () => {
					this.el.parentNode.removeChild( this.el );
				} );
			}, 2000 );
		}
	},
} );
