import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { smoothScrollTo } from '../helpers/smooth-scroll.js';

defineCustomElement( 'mr-program', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.navigationItems = Array.from( this.el.querySelectorAll( '.js-program-navigation-item' ) );
			this.elements.stages = Array.from( this.el.querySelectorAll( '.js-program-stage' ) );

			this.activeStage = 0;
			this.offset = window.innerHeight / 4;
			this.scrollDirection = 'down';
			this.scrollPosition = 0;
			this.waypoints = this.setWaypoints();

			this.stagePadding = 16;
			if ( 768 <= window.innerWidth ) {
				this.stagePadding = 32;
			}
		}

		setWaypoints() {
			const waypoints = new Array();

			this.elements.stages.forEach( ( stage ) => {
				waypoints.push( stage.offsetTop );
			} );

			return waypoints;
		}

		updateUrl( hash ) {
			if ( history.pushState ) {
				history.pushState( null, null, hash );
			} else {
				location.hash = hash;
			}
		}

		updateNavigation( scrollPosition ) {
			let currentStage = this.activeStage;

			const setCurrentNavigationItem = () => {
				const previousNavigationItem = this.elements.navigationItems[this.activeStage];
				const currentNavigationItem = this.elements.navigationItems[currentStage];

				if ( previousNavigationItem && currentNavigationItem ) {
					previousNavigationItem.classList.remove( 'is-active' );
					currentNavigationItem.classList.add( 'is-active' );
					this.updateUrl( `#${currentNavigationItem.dataset.id}` );
					this.activeStage = currentStage;
				}
			};

			const findCurrentStage = () => {
				if ( 'down' === this.scrollDirection ) {
					let index = this.waypoints.findIndex( ( value ) => {
						return value >= ( this.scrollPosition + this.offset );
					} );

					if ( 0 === index ) {
						// scrollpos is before first waypoint
						index = 0;
					} else if ( -1 === index ) {
						// scrollpos is beyond last waypoint
						index = this.elements.stages.length - 1;
					} else {
						index -= 1;
					}

					if ( index !== this.activeStage ) {
						currentStage = index;
						setCurrentNavigationItem();
					}
				} else {
					let index = this.waypoints.findIndex( ( value ) => {
						return this.scrollPosition <= value;
					} );

					if ( -1 === index ) {
						// scrollpos is beyond last waypoint
						index = this.elements.stages.length - 1;
					}

					if ( index !== this.activeStage ) {
						currentStage = index;
						setCurrentNavigationItem();
					}
				}
			};

			if ( scrollPosition > this.scrollPosition ) {
				this.scrollDirection = 'down';
				this.scrollPosition = scrollPosition;
				findCurrentStage();
			} else if ( scrollPosition < this.scrollPosition ) {
				this.scrollDirection = 'up';
				this.scrollPosition = scrollPosition;
				findCurrentStage();
			}
		}

		bind() {
			let scrollThrottle = false;

			this.on( 'click .js-program-navigation-item a', ( e, target ) => {
				e.preventDefault();

				const id = target.getAttribute( 'href' ).replace( '#', '' );
				const destination = this.elements.stages.find( ( stage ) => {
					return stage.getAttribute( 'id' ) === id;
				} );
				console.log( 'EJ' );
				if ( destination ) {
					smoothScrollTo( window.scrollY, destination.offsetTop - this.stagePadding, 800 );
				}
			} );

			this.on( 'scroll', () => {
				if ( !scrollThrottle && this.elements.navigationItems && this.elements.stages ) {
					scrollThrottle = true;
					this.updateNavigation( window.scrollY );

					setTimeout( () => {
						scrollThrottle = false;
						this.updateNavigation( window.scrollY );
					}, 208 );
				}
			}, window, {
				passive: true,
			} );
		}
	},
} );
